import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email, Accounts, Status } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/users/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getRoles } from "store/actions";
import Switch from "react-switch";
import moment from "moment";

const UsersList = props => {
  //meta title
  document.title = "User List | RKI";

  const dispatch = useDispatch();
  const [user, setUser] = useState();
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (user && user.name) || "",
      email: (user && user.email) || "",
      role_id: user?.roles?.length > 0 ? user.roles[0].id : "",
      profile: user?.profile ?? {
        status_expiration: null,
      },
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: user.id,
          ...values,
          user: {
            name: values.name,
            email: values.email,
          },
        };

        // update user
        dispatch(onUpdateUser(updateUser));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          designation: values["designation"],
          email: values["email"],
          tags: values["tags"],
          projects: values["projects"],
        };
        // save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users, roles } = useSelector(state => ({
    users: state.Users.users,
    roles: state.Roles.roles.filter(r => r.guard_name === "api"),
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "profile.status",
        filterable: true,
        Cell: cellProps => <Status {...cellProps} />,
      },
      {
        Header: "Username",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Roles",
        accessor: "roles",
        filterable: true,
        Cell: cellProps => {
          return <Accounts {...cellProps} />;
        },
      },
      {
        Header: "Account",
        accessor: "accounts",
        filterable: true,
        Cell: cellProps => {
          return <Accounts {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
    if (roles && !roles.length) {
      dispatch(getRoles());
      setIsEdit(false);
    }
  }, [dispatch]);

  useEffect(() => {
    setUser(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setUser(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;

    setUser(user);
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setUser(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(user.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Users" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit User" : "Add User"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Label className="form-label">Username</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.name &&
                                  validation.errors.name}
                              </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                label="Email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.email &&
                                  validation.errors.email}
                              </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                              <Label className="form-label">Role</Label>
                              <Input
                                type="select"
                                name="role_id"
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.role_id || ""}
                                invalid={
                                  validation.touched.role_id &&
                                  validation.errors.role_id
                                    ? true
                                    : false
                                }
                              >
                                <option></option>
                                {roles.map(role => (
                                  <option key={role.id} value={role.id}>
                                    {role.name}
                                  </option>
                                ))}
                              </Input>
                              <FormFeedback type="invalid">
                                {validation.touched.role_id &&
                                  validation.errors.role_id}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label>First Name</Label>
                              <Input
                                name="profile.first_name"
                                value={
                                  validation.values.profile?.first_name || ""
                                }
                                onChange={validation.handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label>Middle Name</Label>
                              <Input
                                name="profile.middle_name"
                                value={
                                  validation.values.profile?.middle_name || ""
                                }
                                onChange={validation.handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label>Last Name</Label>
                              <Input
                                name="profile.last_name"
                                value={
                                  validation.values.profile?.last_name || ""
                                }
                                onChange={validation.handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label>Mobile</Label>
                              <Input
                                name="profile.mobile"
                                value={validation.values.profile?.mobile || ""}
                                onChange={validation.handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label>Gender</Label>
                              <Input
                                name="profile.gender"
                                value={validation.values.profile?.gender || ""}
                                onChange={validation.handleChange}
                                type="select"
                              >
                                <option></option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label>Birth Date</Label>
                              <Input
                                name="profile.birth_date"
                                value={
                                  validation.values.profile?.birth_date
                                    ? moment(
                                        validation.values.profile.birth_date
                                      ).format("yyyy-MM-DD")
                                    : ""
                                }
                                onChange={validation.handleChange}
                                type="date"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label>Business Function</Label>
                              <Input
                                name="profile.business_function"
                                value={
                                  validation.values.profile
                                    ?.business_function || ""
                                }
                                onChange={validation.handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <Label>Status</Label>
                            <FormGroup>
                              <Switch
                                name="profile.status"
                                checked={validation.values.profile?.status}
                                onChange={status =>
                                  validation.setFieldValue(
                                    "profile.status",
                                    status
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label>Status Expiration</Label>
                              <Input
                                name="profile.status_expiration"
                                value={
                                  validation.values.profile?.status_expiration
                                    ? moment(
                                        validation.values.profile
                                          .status_expiration
                                      ).format("yyyy-MM-DD")
                                    : ""
                                }
                                onChange={validation.handleChange}
                                type="date"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label>Note</Label>
                              <Input
                                name="profile.note"
                                value={validation.values.profile?.note || ""}
                                onChange={validation.handleChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UsersList);

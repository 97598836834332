import {
  GET_PROPERTY_TERMS,
  GET_PROPERTY_TERMS_FAIL,
  GET_PROPERTY_TERMS_SUCCESS,
  GET_PROPERTY_TERM_DETAIL,
  GET_PROPERTY_TERM_DETAIL_FAIL,
  GET_PROPERTY_TERM_DETAIL_SUCCESS,
  ADD_NEW_PROPERTY_TERM,
  ADD_PROPERTY_TERM_SUCCESS,
  ADD_PROPERTY_TERM_FAIL,
  UPDATE_PROPERTY_TERM,
  UPDATE_PROPERTY_TERM_SUCCESS,
  UPDATE_PROPERTY_TERM_FAIL,
  DELETE_PROPERTY_TERM,
  DELETE_PROPERTY_TERM_SUCCESS,
  DELETE_PROPERTY_TERM_FAIL,
} from "./actionTypes";

export const getPropertyTerms = () => ({
  type: GET_PROPERTY_TERMS,
});

export const getPropertyTermsSuccess = propertyTerms => ({
  type: GET_PROPERTY_TERMS_SUCCESS,
  payload: propertyTerms,
});

export const getPropertyTermsFail = error => ({
  type: GET_PROPERTY_TERMS_FAIL,
  payload: error,
});

export const getPropertyTermDetail = propertyTermId => ({
  type: GET_PROPERTY_TERM_DETAIL,
  propertyTermId,
});

export const getPropertyTermDetailSuccess = propertyTermDetails => ({
  type: GET_PROPERTY_TERM_DETAIL_SUCCESS,
  payload: propertyTermDetails,
});

export const getPropertyTermDetailFail = error => ({
  type: GET_PROPERTY_TERM_DETAIL_FAIL,
  payload: error,
});

export const addNewPropertyTerm = propertyTerm => ({
  type: ADD_NEW_PROPERTY_TERM,
  payload: propertyTerm,
});

export const addPropertyTermSuccess = propertyTerm => ({
  type: ADD_PROPERTY_TERM_SUCCESS,
  payload: propertyTerm,
});

export const addPropertyTermFail = error => ({
  type: ADD_PROPERTY_TERM_FAIL,
  payload: error,
});

export const updatePropertyTerm = propertyTerm => ({
  type: UPDATE_PROPERTY_TERM,
  payload: propertyTerm,
});

export const updatePropertyTermSuccess = propertyTerm => ({
  type: UPDATE_PROPERTY_TERM_SUCCESS,
  payload: propertyTerm,
});

export const updatePropertyTermFail = error => ({
  type: UPDATE_PROPERTY_TERM_FAIL,
  payload: error,
});

export const deletePropertyTerm = propertyTerm => ({
  type: DELETE_PROPERTY_TERM,
  payload: propertyTerm,
});

export const deletePropertyTermSuccess = propertyTerm => ({
  type: DELETE_PROPERTY_TERM_SUCCESS,
  payload: propertyTerm,
});

export const deletePropertyTermFail = error => ({
  type: DELETE_PROPERTY_TERM_FAIL,
  payload: error,
});

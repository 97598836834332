import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginError, loginSuccess, logoutUserSuccess } from "./actions";

import { postLogin, postLogout } from "helpers/Api/auth";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
      guard: user.guard ?? "api",
    });
    if (response.success) {
      localStorage.setItem(
        "authUser",
        JSON.stringify({
          ...response.data.user,
          guard: user.guard,
          accessToken: response.data.token.access_token,
        })
      );
      yield put(loginSuccess(response.data.user));
      window.location.reload(false);
    } else {
      yield put(loginError(response.message));
    }
  } catch (error) {
    yield put(loginError(error.response?.data?.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    const response = yield call(postLogout);
    if (response.success) {
      yield put(logoutUserSuccess(response));
    } else {
      yield put(loginError(response.message));
    }
    history.push("/login");
  } catch (error) {
    yield put(loginError(error?.message));
    history.push("/login");
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;

/* PERMISSIONS */
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL";

/* PERMISSIONS DETAIL*/
export const GET_PERMISSION_DETAIL = "GET_PERMISSION_DETAIL";
export const GET_PERMISSION_DETAIL_SUCCESS = "GET_PERMISSION_DETAIL_SUCCESS";
export const GET_PERMISSION_DETAIL_FAIL = "GET_PERMISSION_DETAIL_FAIL";

export const ADD_NEW_PERMISSION = "ADD_NEW_PERMISSION";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_FAIL = "ADD_PERMISSION_FAIL";

export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAIL = "UPDATE_PERMISSION_FAIL";

export const DELETE_PERMISSION = "DELETE_PERMISSION";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_FAIL = "DELETE_PERMISSION_FAIL";

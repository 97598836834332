import { call, put, takeEvery } from "redux-saga/effects";

// Role Redux States
import {
  GET_ROLES,
  GET_ROLE_DETAIL,
  ADD_NEW_ROLE,
  DELETE_ROLE,
  UPDATE_ROLE,
} from "./actionTypes";
import {
  getRolesSuccess,
  getRolesFail,
  getRoleDetailSuccess,
  getRoleDetailFail,
  addRoleFail,
  addRoleSuccess,
  updateRoleSuccess,
  updateRoleFail,
  deleteRoleSuccess,
  deleteRoleFail,
} from "./actions";

//Include Both Helper File with needed methods
import { RoleApi } from "helpers/Api";

function* fetchRoles() {
  try {
    const response = yield call(RoleApi.all);
    if (response.success) {
      yield put(getRolesSuccess(response.data));
    } else {
      yield put(getRolesFail(response.message));
    }
  } catch (error) {
    yield put(getRolesFail(error));
  }
}

function* fetchRoleDetail({ roleId }) {
  try {
    if (roleId === undefined) {
      yield put(getRoleDetailSuccess(null));
    } else {
      const response = yield call(RoleApi.show, roleId);
      if (response.success) {
        yield put(getRoleDetailSuccess(response.data));
      } else {
        yield put(getRoleDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getRoleDetailFail(error));
  }
}

function* onUpdateRole({ payload: role }) {
  try {
    const response = yield call(RoleApi.update, role);
    if (response.success) {
      yield put(updateRoleSuccess(response.data));
    } else {
      yield put(updateRoleFail(response.message));
    }
  } catch (error) {
    yield put(updateRoleFail(error));
  }
}

function* onDeleteRole({ payload: role }) {
  try {
    const response = yield call(RoleApi.destroy, role);
    if (response.success) {
      yield put(deleteRoleSuccess(response.data));
    } else {
      yield put(deleteRoleFail(response.message));
    }
  } catch (error) {
    yield put(deleteRoleFail(error));
  }
}

function* onAddNewRole({ payload: role }) {
  try {
    const response = yield call(RoleApi.create, role);
    if (response.success) {
      yield put(addRoleSuccess(response.data));
    } else {
      yield put(addRoleFail(response.message));
    }
  } catch (error) {
    yield put(addRoleFail(error));
  }
}

function* rolesSaga() {
  yield takeEvery(GET_ROLES, fetchRoles);
  yield takeEvery(GET_ROLE_DETAIL, fetchRoleDetail);
  yield takeEvery(ADD_NEW_ROLE, onAddNewRole);
  yield takeEvery(UPDATE_ROLE, onUpdateRole);
  yield takeEvery(DELETE_ROLE, onDeleteRole);
}

export default rolesSaga;

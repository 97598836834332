import {
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSION_DETAIL_FAIL,
  GET_PERMISSION_DETAIL_SUCCESS,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAIL,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAIL,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  permissions: [],
  permissionDetail: {},
  error: {},
};

const Permissions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      };

    case GET_PERMISSIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PERMISSION_DETAIL_SUCCESS:
      return {
        ...state,
        permissionDetail: action.payload,
      };

    case GET_PERMISSION_DETAIL_FAIL:
      return {
        ...state,
        permissionDetail: null,
        error: action.payload,
      };

    case ADD_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: [...state.permissions, action.payload],
      };

    case ADD_PERMISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: state.permissions.map(permission =>
          permission.id.toString() === action.payload.id.toString()
            ? { permission, ...action.payload }
            : permission
        ),
      };

    case UPDATE_PERMISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: state.permissions.filter(
          permission => permission.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PERMISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Permissions;

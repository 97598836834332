import { call, put, takeEvery } from "redux-saga/effects";

// Permission Redux States
import {
  GET_PERMISSIONS,
  GET_PERMISSION_DETAIL,
  ADD_NEW_PERMISSION,
  DELETE_PERMISSION,
  UPDATE_PERMISSION,
} from "./actionTypes";
import {
  getPermissionsSuccess,
  getPermissionsFail,
  getPermissionDetailSuccess,
  getPermissionDetailFail,
  addPermissionFail,
  addPermissionSuccess,
  updatePermissionSuccess,
  updatePermissionFail,
  deletePermissionSuccess,
  deletePermissionFail,
} from "./actions";

//Include Both Helper File with needed methods
import { PermissionApi } from "helpers/Api";

function* fetchPermissions() {
  try {
    const response = yield call(PermissionApi.all);
    if (response.success) {
      yield put(getPermissionsSuccess(response.data));
    } else {
      yield put(getPermissionsFail(response.message));
    }
  } catch (error) {
    yield put(getPermissionsFail(error));
  }
}

function* fetchPermissionDetail({ permissionId }) {
  try {
    if (permissionId === undefined) {
      yield put(getPermissionDetailSuccess(null));
    } else {
      const response = yield call(PermissionApi.show, permissionId);
      if (response.success) {
        yield put(getPermissionDetailSuccess(response.data));
      } else {
        yield put(getPermissionDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getPermissionDetailFail(error));
  }
}

function* onUpdatePermission({ payload: permission }) {
  try {
    const response = yield call(PermissionApi.update, permission);
    if (response.success) {
      yield put(updatePermissionSuccess(response.data));
    } else {
      yield put(updatePermissionFail(response.message));
    }
  } catch (error) {
    yield put(updatePermissionFail(error));
  }
}

function* onDeletePermission({ payload: permission }) {
  try {
    const response = yield call(PermissionApi.destroy, permission);
    if (response.success) {
      yield put(deletePermissionSuccess(response.data));
    } else {
      yield put(deletePermissionFail(response.message));
    }
  } catch (error) {
    yield put(deletePermissionFail(error));
  }
}

function* onAddNewPermission({ payload: permission }) {
  try {
    const response = yield call(PermissionApi.create, permission);
    if (response.success) {
      yield put(addPermissionSuccess(response.data));
    } else {
      yield put(addPermissionFail(response.message));
    }
  } catch (error) {
    yield put(addPermissionFail(error));
  }
}

function* permissionsSaga() {
  yield takeEvery(GET_PERMISSIONS, fetchPermissions);
  yield takeEvery(GET_PERMISSION_DETAIL, fetchPermissionDetail);
  yield takeEvery(ADD_NEW_PERMISSION, onAddNewPermission);
  yield takeEvery(UPDATE_PERMISSION, onUpdatePermission);
  yield takeEvery(DELETE_PERMISSION, onDeletePermission);
}

export default permissionsSaga;

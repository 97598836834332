import { get, post, del } from "../api_helper";

const createApiRepository = URI => ({
  // get all entities
  all() {
    return get(URI);
  },

  // get single entity
  show(id) {
    return get(`${URI}/${id}`);
  },

  // add an entity
  create(data) {
    return post(URI, data);
  },

  // update an entity
  update(data) {
    return post(`${URI}/${data.id}`, data);
  },

  // delete an entity
  destroy(id, force = true) {
    return del(`${URI}/${id}?force=${force ? 1 : 0}`);
  },
});

export default createApiRepository;

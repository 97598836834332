import * as url from "../url_helper";
import createApiRepository from "./repository";

export const AccountApi = createApiRepository(url.ACCOUNTS_URI);
export const PropertyApi = createApiRepository(url.PROPERTIES_URI);
export const UserApi = createApiRepository(url.USERS_URI);
export const ProjectApi = createApiRepository(url.PROJECTS_URI);
export const PropertyTermApi = createApiRepository(url.PROPERTY_TERMS_URI);
export const RoleApi = createApiRepository(url.ROLES_URI);
export const PermissionApi = createApiRepository(url.PERMISSIONS_URI);
export const AdminApi = createApiRepository(url.ADMINS_URI);

import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { size, map } from "lodash";

const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y";
  const date1 = moment(new Date(date)).format(dateFormat);
  return date1;
};
const toLowerCase1 = str => {
  return str === "" || str === undefined ? "" : str.toLowerCase();
};

const Name = cell => {
  return cell.value ? cell.value : "";
};

const Email = cell => {
  return cell.value ? cell.value : "";
};

const Status = cell => {
  return (
    <span
      className={`badge badge-soft-${
        cell.value ? "success" : "warning"
      } font-size-11 m-1`}
    >
      {cell.value ? "Active" : "Inactive"}
    </span>
  );
};

const Accounts = cell => {
  return (
    <>
      {map(
        cell.value,
        (tag, index) =>
          index < 2 && (
            <div key={"_account_" + tag.type + index}>
              <span className="badge badge-soft-primary font-size-11 m-1">
                {tag.type}
              </span>{" "}
              {tag.name}
            </div>
          )
      )}
      {size(cell.value) > 2 && (
        <span className="badge badge-soft-primary font-size-11 m-1">
          {size(cell.value) - 1} + more
        </span>
      )}
    </>
  );
};

const Roles = cell => {
  return (
    <>
      {map(
        cell.value,
        (role, index) =>
          index < 2 && (
            <div key={"_account_" + role.name + index}>
              <span className="badge badge-soft-success font-size-11 m-1">
                {role.name}
              </span>
            </div>
          )
      )}
      {size(cell.value) > 2 && (
        <span className="badge badge-soft-success font-size-11 m-1">
          {size(cell.value) - 1} + more
        </span>
      )}
    </>
  );
};

const Projects = cell => {
  return cell.value ? cell.value : "";
};

const Img = cell => {
  return (
    <>
      {!cell.value ? (
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle">
            {console.log("cell", cell.data[0].name)}
            {cell.data[0].name.charAt(0)}
          </span>
        </div>
      ) : (
        <div>
          <img className="rounded-circle avatar-xs" src={cell.value} alt="" />
        </div>
      )}
    </>
  );
};

export { Name, Email, Accounts, Projects, Img, Roles, Status };

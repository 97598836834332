import { call, put, takeEvery } from "redux-saga/effects";

// Project Redux States
import {
  GET_PROJECTS,
  GET_PROJECT_DETAIL,
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
} from "./actionTypes";
import {
  getProjectsSuccess,
  getProjectsFail,
  getProjectDetailSuccess,
  getProjectDetailFail,
  addProjectFail,
  addProjectSuccess,
  updateProjectSuccess,
  updateProjectFail,
  deleteProjectSuccess,
  deleteProjectFail,
} from "./actions";

//Include Both Helper File with needed methods
import { ProjectApi } from "helpers/Api";

function* fetchProjects() {
  try {
    const response = yield call(ProjectApi.all);
    if (response.success) {
      yield put(getProjectsSuccess(response.data));
    } else {
      yield put(getProjectsFail(response.message));
    }
  } catch (error) {
    yield put(getProjectsFail(error));
  }
}

function* fetchProjectDetail({ projectId }) {
  try {
    if (projectId === undefined) {
      yield put(getProjectDetailSuccess(null));
    } else {
      const response = yield call(ProjectApi.show, projectId);
      if (response.success) {
        yield put(getProjectDetailSuccess(response.data));
      } else {
        yield put(getProjectDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getProjectDetailFail(error));
  }
}

function* onUpdateProject({ payload: project }) {
  try {
    const response = yield call(ProjectApi.update, project);
    if (response.success) {
      yield put(updateProjectSuccess(response.data));
    } else {
      yield put(updateProjectFail(response.message));
    }
  } catch (error) {
    yield put(updateProjectFail(error));
  }
}

function* onDeleteProject({ payload: project }) {
  try {
    const response = yield call(ProjectApi.destroy, project);
    if (response.success) {
      yield put(deleteProjectSuccess(response.data));
    } else {
      yield put(deleteProjectFail(response.message));
    }
  } catch (error) {
    yield put(deleteProjectFail(error));
  }
}

function* onAddNewProject({ payload: project }) {
  try {
    const response = yield call(ProjectApi.create, project);
    if (response.success) {
      yield put(addProjectSuccess(response.data));
    } else {
      yield put(addProjectFail(response.message));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* projectsSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects);
  yield takeEvery(GET_PROJECT_DETAIL, fetchProjectDetail);
  yield takeEvery(ADD_NEW_PROJECT, onAddNewProject);
  yield takeEvery(UPDATE_PROJECT, onUpdateProject);
  yield takeEvery(DELETE_PROJECT, onDeleteProject);
}

export default projectsSaga;

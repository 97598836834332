import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link, useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Common Components
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import TableContainer from "components/Common/TableContainer";

import {
  getRoles as onGetRoles,
  addNewRole as onAddNewRole,
  updateRole as onUpdateRole,
  deleteRole as onDeleteRole,
  getRoleDetail,
  getPermissions,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { map, size } from "lodash";

const RolesList = props => {
  //meta title
  document.title = "Roles List | RKI";

  const dispatch = useDispatch();
  const [role, setRole] = useState();

  const { roles, roleDetail, permissions } = useSelector(state => ({
    roles: state.Roles.roles,
    roleDetail: state.Roles.roleDetail,
    permissions: state.Permissions.permissions,
  }));

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: role?.name ?? "",
      guard_name: role?.guard_name ?? "",
      permissions: role?.permissions ?? [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      guard_name: Yup.string().required(),
    }),
    onSubmit: values => {
      const newRole = {
        id: roleDetail.id,
        ...values,
        permissions: values.permissions.map(p => p.id),
      };
      if (isEdit) {
        // update role
        dispatch(onUpdateRole(newRole));
        validation.resetForm();
        setIsEdit(false);
      } else {
        // save new role
        dispatch(onAddNewRole(newRole));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Guard",
        accessor: "guard_name",
        filterable: true,
      },
      {
        Header: "Permissions",
        accessor: "permissions",
        filterable: true,
        Cell: cellProps => (
          <>
            {map(
              cellProps.value,
              (permission, index) =>
                index < 2 && (
                  <span
                    className="badge badge-soft-primary font-size-11 m-1"
                    key={"_permission_" + index}
                  >
                    {permission.name.replace(
                      `${cellProps.row?.original?.guard_name}.`,
                      ""
                    )}
                  </span>
                )
            )}
            {size(cellProps.value) > 2 && (
              <span className="badge badge-soft-primary font-size-11 m-1">
                {size(cellProps.value) - 1} + more
              </span>
            )}
          </>
        ),
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleRoleClick(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const data = cellProps.row.original;
                  onClickDelete(data);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (roles && !roles.length) {
      dispatch(onGetRoles());
      setIsEdit(false);
    }
    if (permissions && !permissions.length) {
      dispatch(getPermissions());
      setIsEdit(false);
    }
  }, [dispatch]);

  useEffect(() => {
    setRole(roleDetail);
  }, [roleDetail]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleRoleClick = async arg => {
    await dispatch(getRoleDetail(arg.id));
    setIsEdit(true);

    setModal(true);
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = role => {
    setRole(role);
    setDeleteModal(true);
  };

  const handleDeleteRole = () => {
    dispatch(onDeleteRole(role.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
    setRole();
  };

  const handleRoleClicks = () => {
    dispatch(getRoleDetail(0));
    setIsEdit(false);
    setModal(true);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRole}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Roles" breadcrumbItem="Roles List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={roles}
                    isGlobalFilter={true}
                    isAddOptions="role"
                    handleOptionClicks={handleRoleClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit " : "Add "}{" "}
                      <span className="text-capitalize">Role</span>
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col lg={6}>
                            <FormGroup>
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.name &&
                                  validation.errors.name}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg={6}>
                            <FormGroup>
                              <Label className="form-label">Guard</Label>
                              <Input
                                name="guard_name"
                                type="select"
                                onChange={e => {
                                  validation.handleChange(e);
                                  validation.setFieldValue("permissions", []);
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.guard_name || ""}
                                invalid={
                                  validation.touched.guard_name &&
                                  validation.errors.guard_name
                                    ? true
                                    : false
                                }
                              >
                                <option></option>
                                <option>admin</option>
                                <option>api</option>
                              </Input>
                              <FormFeedback type="invalid">
                                {validation.touched.guard_name &&
                                  validation.errors.guard_name}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <Label>Permissions</Label>
                            <Row>
                              {permissions
                                .filter(p =>
                                  p.name.startsWith(
                                    `${validation.values.guard_name}.`
                                  )
                                )
                                .map(p => (
                                  <Col md="6" key={p.id}>
                                    <FormGroup check>
                                      <Input
                                        type="checkbox"
                                        name="permissions"
                                        value={p.id}
                                        checked={validation.values.permissions
                                          .map(p => p.id)
                                          .includes(parseInt(p.id))}
                                        onClick={e => {
                                          if (!e.target.checked) {
                                            validation.setFieldValue(
                                              "permissions",
                                              [
                                                ...validation.values
                                                  .permissions,
                                                p,
                                              ]
                                            );
                                          } else {
                                            validation.setFieldValue(
                                              "permissions",
                                              validation.values.permissions.filter(
                                                perm =>
                                                  parseInt(perm.id) !==
                                                  parseInt(p.id)
                                              )
                                            );
                                          }
                                        }}
                                      />
                                      <Label check>
                                        {p.name.replace(
                                          `${validation.values.guard_name}.`,
                                          ""
                                        )}
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                ))}
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-role"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(RolesList);

import {
  GET_PROPERTIES_SUCCESS,
  GET_PROPERTIES_FAIL,
  ADD_PROPERTY_SUCCESS,
  ADD_PROPERTY_FAIL,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAIL,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAIL,
  GET_PROPERTY_DETAIL_SUCCESS,
  GET_PROPERTY_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  properties: [],
  propertyDetail: {},
  error: {},
}

const property = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: action.payload,
      }

    case GET_PROPERTIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PROPERTY_SUCCESS:

      return {
        ...state,
        properties: [...state.properties, action.payload],
      }

    case ADD_PROPERTY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROPERTY_DETAIL_SUCCESS:
      return {
        ...state,
        propertyDetail: action.payload,
      }

      case UPDATE_PROPERTY_SUCCESS:
        return {
          ...state,
          properties: state.properties.map(property =>
            property.id.toString() === action.payload.id.toString()
              ? { property, ...action.payload }
              : property
          ),
        }
  
      case UPDATE_PROPERTY_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case DELETE_PROPERTY_SUCCESS:
        return {
          ...state,
          properties: state.properties.filter(
            property => property.id.toString() !== action.payload.id.toString()
          ),
        }
  
      case DELETE_PROPERTY_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    case GET_PROPERTY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default property

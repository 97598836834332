/* PROPERTY_TERMS */
export const GET_PROPERTY_TERMS = "GET_PROPERTY_TERMS";
export const GET_PROPERTY_TERMS_SUCCESS = "GET_PROPERTY_TERMS_SUCCESS";
export const GET_PROPERTY_TERMS_FAIL = "GET_PROPERTY_TERMS_FAIL";

/* PROPERTY_TERMS DETAIL*/
export const GET_PROPERTY_TERM_DETAIL = "GET_PROPERTY_TERM_DETAIL";
export const GET_PROPERTY_TERM_DETAIL_SUCCESS =
  "GET_PROPERTY_TERM_DETAIL_SUCCESS";
export const GET_PROPERTY_TERM_DETAIL_FAIL = "GET_PROPERTY_TERM_DETAIL_FAIL";

export const ADD_NEW_PROPERTY_TERM = "ADD_NEW_PROPERTY_TERM";
export const ADD_PROPERTY_TERM_SUCCESS = "ADD_PROPERTY_TERM_SUCCESS";
export const ADD_PROPERTY_TERM_FAIL = "ADD_PROPERTY_TERM_FAIL";

export const UPDATE_PROPERTY_TERM = "UPDATE_PROPERTY_TERM";
export const UPDATE_PROPERTY_TERM_SUCCESS = "UPDATE_PROPERTY_TERM_SUCCESS";
export const UPDATE_PROPERTY_TERM_FAIL = "UPDATE_PROPERTY_TERM_FAIL";

export const DELETE_PROPERTY_TERM = "DELETE_PROPERTY_TERM";
export const DELETE_PROPERTY_TERM_SUCCESS = "DELETE_PROPERTY_TERM_SUCCESS";
export const DELETE_PROPERTY_TERM_FAIL = "DELETE_PROPERTY_TERM_FAIL";

import { call, put, takeEvery } from "redux-saga/effects";

// Account Redux States
import {
  GET_ACCOUNTS,
  GET_ACCOUNT_DETAIL,
  ADD_NEW_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_ACCOUNT,
} from "./actionTypes";
import {
  getAccountsSuccess,
  getAccountsFail,
  getAccountDetailSuccess,
  getAccountDetailFail,
  addAccountFail,
  addAccountSuccess,
  updateAccountSuccess,
  updateAccountFail,
  deleteAccountSuccess,
  deleteAccountFail,
} from "./actions";

//Include Both Helper File with needed methods
import { AccountApi } from "helpers/Api";

function* fetchAccounts() {
  try {
    const response = yield call(AccountApi.all);
    if (response.success) {
      yield put(getAccountsSuccess(response.data));
    } else {
      yield put(getAccountsFail(response.message));
    }
  } catch (error) {
    yield put(getAccountsFail(error));
  }
}

function* fetchAccountDetail({ accountId }) {
  try {
    if (accountId === undefined) {
      yield put(getAccountDetailSuccess(null));
    } else {
      const response = yield call(AccountApi.show, accountId);
      if (response.success) {
        yield put(getAccountDetailSuccess(response.data));
      } else {
        yield put(getAccountDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getAccountDetailFail(error));
  }
}

function* onUpdateAccount({ payload: account }) {
  try {
    const response = yield call(AccountApi.update, account);
    if (response.success) {
      yield put(updateAccountSuccess(response.data));
    } else {
      yield put(updateAccountFail(response.message));
    }
  } catch (error) {
    yield put(updateAccountFail(error));
  }
}

function* onDeleteAccount({ payload: account }) {
  try {
    const response = yield call(AccountApi.destroy, account);
    if (response.success) {
      yield put(deleteAccountSuccess(response.data));
    } else {
      yield put(deleteAccountFail(response.message));
    }
  } catch (error) {
    yield put(deleteAccountFail(error));
  }
}

function* onAddNewAccount({ payload: account }) {
  try {
    const response = yield call(AccountApi.create, account);
    if (response.success) {
      yield put(addAccountSuccess(response.data));
    } else {
      yield put(addAccountFail(response.message));
    }
  } catch (error) {
    yield put(addAccountFail(error));
  }
}

function* accountsSaga() {
  yield takeEvery(GET_ACCOUNTS, fetchAccounts);
  yield takeEvery(GET_ACCOUNT_DETAIL, fetchAccountDetail);
  yield takeEvery(ADD_NEW_ACCOUNT, onAddNewAccount);
  yield takeEvery(UPDATE_ACCOUNT, onUpdateAccount);
  yield takeEvery(DELETE_ACCOUNT, onDeleteAccount);
}

export default accountsSaga;

/**
 * PROPERTIES
 */
export const GET_PROPERTIES = "GET_PROPERTIES";
export const GET_PROPERTIES_FAIL = "GET_PROPERTIES_FAIL";
export const GET_PROPERTIES_SUCCESS = "GET_PROPERTIES_SUCCESS";

/**
 * PROPERTIES PROFILE
 */
export const GET_PROPERTY_DETAIL = "GET_PROPERTY_DETAIL";
export const GET_PROPERTY_DETAIL_FAIL = "GET_PROPERTY_DETAIL_FAIL";
export const GET_PROPERTY_DETAIL_SUCCESS = "GET_PROPERTY_DETAIL_SUCCESS";

/**
 * ADD PROPERTY
 */
export const ADD_NEW_PROPERTY = "ADD_NEW_PROPERTY";
export const ADD_PROPERTY_SUCCESS = "ADD_PROPERTY_SUCCESS";
export const ADD_PROPERTY_FAIL = "ADD_PROPERTY_FAIL";

/**
 * EDIT PROPERTY
 */
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_FAIL = "UPDATE_PROPERTY_FAIL";

/**
 * DELETE PROPERTY
 */
export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_FAIL = "DELETE_PROPERTY_FAIL";

/* ACCOUNTS */
export const GET_ACCOUNTS = "GET_ACCOUNTS"
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS"
export const GET_ACCOUNTS_FAIL = "GET_ACCOUNTS_FAIL"

/* ACCOUNTS DETAIL*/
export const GET_ACCOUNT_DETAIL = "GET_ACCOUNT_DETAIL"
export const GET_ACCOUNT_DETAIL_SUCCESS = "GET_ACCOUNT_DETAIL_SUCCESS"
export const GET_ACCOUNT_DETAIL_FAIL = "GET_ACCOUNT_DETAIL_FAIL"

export const ADD_NEW_ACCOUNT = "ADD_NEW_ACCOUNT"
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS"
export const ADD_ACCOUNT_FAIL = "ADD_ACCOUNT_FAIL"

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT"
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS"
export const UPDATE_ACCOUNT_FAIL = "UPDATE_ACCOUNT_FAIL"

export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS"
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL"

import {
  GET_PROPERTY_TERMS_FAIL,
  GET_PROPERTY_TERMS_SUCCESS,
  GET_PROPERTY_TERM_DETAIL_FAIL,
  GET_PROPERTY_TERM_DETAIL_SUCCESS,
  ADD_PROPERTY_TERM_SUCCESS,
  ADD_PROPERTY_TERM_FAIL,
  UPDATE_PROPERTY_TERM_SUCCESS,
  UPDATE_PROPERTY_TERM_FAIL,
  DELETE_PROPERTY_TERM_SUCCESS,
  DELETE_PROPERTY_TERM_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  propertyTerms: [],
  propertyTermDetail: {},
  error: {},
}

const PropertyTerms = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROPERTY_TERMS_SUCCESS:
      return {
        ...state,
        propertyTerms: action.payload,
      }

    case GET_PROPERTY_TERMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROPERTY_TERM_DETAIL_SUCCESS:
      return {
        ...state,
        propertyTermDetail: action.payload,
      }

    case GET_PROPERTY_TERM_DETAIL_FAIL:
      return {
        ...state,
        propertyTermDetail: null,
        error: action.payload,
      }

    case ADD_PROPERTY_TERM_SUCCESS:
      return {
        ...state,
        propertyTerms: [...state.propertyTerms, action.payload],
      }

    case ADD_PROPERTY_TERM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PROPERTY_TERM_SUCCESS:
      return {
        ...state,
        propertyTerms: state.propertyTerms.map(propertyTerm =>
          propertyTerm.id.toString() === action.payload.id.toString()
            ? { propertyTerm, ...action.payload }
            : propertyTerm
        ),
      }

    case UPDATE_PROPERTY_TERM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROPERTY_TERM_SUCCESS:
      return {
        ...state,
        propertyTerms: state.propertyTerms.filter(
          propertyTerm => propertyTerm.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_PROPERTY_TERM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default PropertyTerms

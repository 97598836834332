import { call, put, takeEvery } from "redux-saga/effects";

// PropertyTerm Redux States
import {
  GET_PROPERTY_TERMS,
  GET_PROPERTY_TERM_DETAIL,
  ADD_NEW_PROPERTY_TERM,
  DELETE_PROPERTY_TERM,
  UPDATE_PROPERTY_TERM,
} from "./actionTypes";
import {
  getPropertyTermsSuccess,
  getPropertyTermsFail,
  getPropertyTermDetailSuccess,
  getPropertyTermDetailFail,
  addPropertyTermFail,
  addPropertyTermSuccess,
  updatePropertyTermSuccess,
  updatePropertyTermFail,
  deletePropertyTermSuccess,
  deletePropertyTermFail,
} from "./actions";

//Include Both Helper File with needed methods
import { PropertyTermApi } from "helpers/Api";

function* fetchPropertyTerms() {
  try {
    const response = yield call(PropertyTermApi.all);
    if (response.success) {
      yield put(getPropertyTermsSuccess(response.data));
    } else {
      yield put(getPropertyTermsFail(response.message));
    }
  } catch (error) {
    yield put(getPropertyTermsFail(error));
  }
}

function* fetchPropertyTermDetail({ propertyTermId }) {
  try {
    if (propertyTermId === undefined) {
      yield put(getPropertyTermDetailSuccess(null));
    } else {
      const response = yield call(PropertyTermApi.show, propertyTermId);
      if (response.success) {
        yield put(getPropertyTermDetailSuccess(response.data));
      } else {
        yield put(getPropertyTermDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getPropertyTermDetailFail(error));
  }
}

function* onUpdatePropertyTerm({ payload: propertyTerm }) {
  try {
    const response = yield call(PropertyTermApi.update, propertyTerm);
    if (response.success) {
      yield put(updatePropertyTermSuccess(response.data));
    } else {
      yield put(updatePropertyTermFail(response.message));
    }
  } catch (error) {
    yield put(updatePropertyTermFail(error));
  }
}

function* onDeletePropertyTerm({ payload: propertyTerm }) {
  try {
    const response = yield call(PropertyTermApi.destroy, propertyTerm);
    if (response.success) {
      yield put(deletePropertyTermSuccess(response.data));
    } else {
      yield put(deletePropertyTermFail(response.message));
    }
  } catch (error) {
    yield put(deletePropertyTermFail(error));
  }
}

function* onAddNewPropertyTerm({ payload: propertyTerm }) {
  try {
    const response = yield call(PropertyTermApi.create, propertyTerm);
    if (response.success) {
      yield put(addPropertyTermSuccess(response.data));
    } else {
      yield put(addPropertyTermFail(response.message));
    }
  } catch (error) {
    yield put(addPropertyTermFail(error));
  }
}

function* propertyTermsSaga() {
  yield takeEvery(GET_PROPERTY_TERMS, fetchPropertyTerms);
  yield takeEvery(GET_PROPERTY_TERM_DETAIL, fetchPropertyTermDetail);
  yield takeEvery(ADD_NEW_PROPERTY_TERM, onAddNewPropertyTerm);
  yield takeEvery(UPDATE_PROPERTY_TERM, onUpdatePropertyTerm);
  yield takeEvery(DELETE_PROPERTY_TERM, onDeletePropertyTerm);
}

export default propertyTermsSaga;

/* ROLES */
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";

/* ROLES DETAIL*/
export const GET_ROLE_DETAIL = "GET_ROLE_DETAIL";
export const GET_ROLE_DETAIL_SUCCESS = "GET_ROLE_DETAIL_SUCCESS";
export const GET_ROLE_DETAIL_FAIL = "GET_ROLE_DETAIL_FAIL";

export const ADD_NEW_ROLE = "ADD_NEW_ROLE";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";

import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSION_DETAIL,
  GET_PERMISSION_DETAIL_FAIL,
  GET_PERMISSION_DETAIL_SUCCESS,
  ADD_NEW_PERMISSION,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAIL,
  UPDATE_PERMISSION,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAIL,
  DELETE_PERMISSION,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAIL,
} from "./actionTypes";

export const getPermissions = () => ({
  type: GET_PERMISSIONS,
});

export const getPermissionsSuccess = permissions => ({
  type: GET_PERMISSIONS_SUCCESS,
  payload: permissions,
});

export const getPermissionsFail = error => ({
  type: GET_PERMISSIONS_FAIL,
  payload: error,
});

export const getPermissionDetail = permissionId => ({
  type: GET_PERMISSION_DETAIL,
  permissionId,
});

export const getPermissionDetailSuccess = permissionDetails => ({
  type: GET_PERMISSION_DETAIL_SUCCESS,
  payload: permissionDetails,
});

export const getPermissionDetailFail = error => ({
  type: GET_PERMISSION_DETAIL_FAIL,
  payload: error,
});

export const addNewPermission = permission => ({
  type: ADD_NEW_PERMISSION,
  payload: permission,
});

export const addPermissionSuccess = permission => ({
  type: ADD_PERMISSION_SUCCESS,
  payload: permission,
});

export const addPermissionFail = error => ({
  type: ADD_PERMISSION_FAIL,
  payload: error,
});

export const updatePermission = permission => ({
  type: UPDATE_PERMISSION,
  payload: permission,
});

export const updatePermissionSuccess = permission => ({
  type: UPDATE_PERMISSION_SUCCESS,
  payload: permission,
});

export const updatePermissionFail = error => ({
  type: UPDATE_PERMISSION_FAIL,
  payload: error,
});

export const deletePermission = permission => ({
  type: DELETE_PERMISSION,
  payload: permission,
});

export const deletePermissionSuccess = permission => ({
  type: DELETE_PERMISSION_SUCCESS,
  payload: permission,
});

export const deletePermissionFail = error => ({
  type: DELETE_PERMISSION_FAIL,
  payload: error,
});

import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//jobs
import JobReducer from "./jobs/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Properties
import Properties from "./properties/reducer";

//Accounts
import Accounts from "./accounts/reducer";

//PropertyTerms
import PropertyTerms from "./property-terms/reducer";

//Users
import Users from "./users/reducer";

//Roles
import Roles from "./roles/reducer";

//Permissions
import Permissions from "./permissions/reducer";

//Admins
import Admins from "./admins/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  JobReducer,
  projects,
  tasks,
  Dashboard,
  DashboardSaas,
  Properties,
  Accounts,
  PropertyTerms,
  Users,
  Roles,
  Permissions,
  Admins,
});

export default rootReducer;

import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getAccounts as onGetAccounts,
  addNewAccount as onAddNewAccount,
  updateAccount as onUpdateAccount,
  deleteAccount as onDeleteAccount,
  getAccountDetail,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { FormLabel } from "@material-ui/core";
import moment from "moment";

const AccountsList = props => {
  const pathName = props.location.pathname.split("/").pop();
  const AccountType = pathName;

  //meta title
  document.title = "Accounts List | RKI";

  const dispatch = useDispatch();
  const [account, setAccount] = useState();

  const { accounts, accountDetail } = useSelector(state => ({
    accounts: state.Accounts.accounts.filter(
      account => account.type === AccountType
    ),
    accountDetail: state.Accounts.accountDetail,
  }));

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: account?.name ?? "",
      type: AccountType,
      image: account?.image ?? "",
      phone: account?.phone ?? "",
      email: account?.email ?? "",
      address: account?.address ?? "",
      max_accounts: account?.max_accounts ?? 1,
      max_storage: account?.max_storage ?? 1000,
      status: account?.status ?? 1,
      status_expiration: account?.status_expiration
        ? moment(account.status_expiration).format("yyyy-MM-DDThh:mm")
        : undefined,
      users:
        account?.users?.map(user => ({
          label: user.email,
          value: user.email,
        })) ?? [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      image: Yup.string().nullable(),
      phone: Yup.string().required(),
      email: Yup.string().email().required(),
      address: Yup.string().required(),
      max_accounts: Yup.number().min(1).required(),
      max_storage: Yup.number().required(),
      status: Yup.number().required(),
      status_expiration: Yup.date().nullable(),
    }),
    onSubmit: values => {
      let newAccount = {
        ...values,
        users: values.users.map(u => u.value),
      };
      if (isEdit) {
        newAccount.id = accountDetail.id;
        // update user
        dispatch(onUpdateAccount(newAccount));
      } else {
        // save new user
        dispatch(onAddNewAccount(newAccount));
      }
      validation.resetForm();
      setIsEdit(false);
      toggle();
    },
  });

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Phone",
        accessor: "phone",
        filterable: true,
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
      },
      {
        Header: "Status Expiration",
        accessor: row =>
          row.status_expiration &&
          moment(row.status_expiration).format("DD/MM/yyyy hh:mm a"),
        filterable: true,
      },
      {
        Header: "Max Accounts",
        accessor: "max_accounts",
        filterable: true,
      },
      {
        Header: "Created At",
        accessor: row => moment(row.created_at).format("DD/MM/yyyy hh:mm a"),
        filterable: true,
      },
      {
        Header: "Last Updated At",
        accessor: row => moment(row.updated_at).format("DD/MM/yyyy hh:mm a"),
        filterable: true,
      },
      {
        Header: "Account ID",
        accessor: "guid",
        filterable: true,
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleAccountClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (accounts && !accounts.length) {
      dispatch(onGetAccounts());
      setIsEdit(false);
    }
  }, [dispatch]);

  useEffect(() => {
    setAccount(accountDetail);
  }, [accountDetail]);

  // useEffect(() => {
  //   if (!isEmpty(accounts) && !!isEdit) {
  //     setAccount(accounts);
  //     setIsEdit(false);
  //   }
  // }, [accounts]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleAccountClick = async arg => {
    await dispatch(getAccountDetail(arg.id));
    setIsEdit(true);
    setModal(true);
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = account => {
    setAccount(account);
    setDeleteModal(true);
  };

  const handleDeleteAccount = () => {
    dispatch(onDeleteAccount(account.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleAccountClicks = () => {
    dispatch(getAccountDetail(0));
    setIsEdit(false);
    setModal(true);
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAccount}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={AccountType}
            breadcrumbItem={`${AccountType} List`}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={accounts}
                    isGlobalFilter={true}
                    isAddOptions={AccountType}
                    handleOptionClicks={handleAccountClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit " : "Add "}{" "}
                      <span className="text-capitalize">{AccountType}</span>
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.name &&
                                  validation.errors.name}
                              </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                label="Email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.email &&
                                  validation.errors.email}
                              </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                              <Label className="form-label">Phone</Label>
                              <Input
                                name="phone"
                                label="Phone"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone &&
                                  validation.errors.phone
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.phone &&
                                  validation.errors.phone}
                              </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                              <Label className="form-label">Address</Label>
                              <Input
                                name="address"
                                label="Address"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.address &&
                                  validation.errors.address}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label className="form-label">Max Accounts</Label>
                              <Input
                                name="max_accounts"
                                label="Max Accounts"
                                type="number"
                                min="1"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.max_accounts || ""}
                                invalid={
                                  validation.touched.max_accounts &&
                                  validation.errors.max_accounts
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.max_accounts &&
                                  validation.errors.max_accounts}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label className="form-label">Max Storage</Label>
                              <Input
                                name="max_storage"
                                label="Max Storage"
                                type="number"
                                min="1"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.max_storage || ""}
                                invalid={
                                  validation.touched.max_storage &&
                                  validation.errors.max_storage
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.max_storage &&
                                  validation.errors.max_storage}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label className="form-label">Status</Label>
                              <Input
                                name="status"
                                label="Status"
                                type="number"
                                min="0"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.status || ""}
                                invalid={
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.status &&
                                  validation.errors.status}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label className="form-label">
                                Status Expiration
                              </Label>
                              <Input
                                name="status_expiration"
                                label="Status Expiration"
                                type="datetime-local"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.status_expiration || ""
                                }
                                invalid={
                                  validation.touched.status_expiration &&
                                  validation.errors.status_expiration
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.status_expiration &&
                                  validation.errors.status_expiration}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <FormLabel>Users</FormLabel>
                              <CreatableSelect
                                isClearable
                                isMulti
                                value={validation.values.users}
                                onChange={options =>
                                  validation.setFieldValue("users", options)
                                }
                              />

                              {/* <MultipleValueTextInput
                                name="users"
                                values={validation.values.users}
                                className="form-control"
                                placeholder="Enter user email; separate them with COMMA or ENTER."
                                shouldAddOnBlur={true}
                                onItemAdded={(_, resultItems) =>
                                  validation.setFieldValue("users", resultItems)
                                }
                                onItemDeleted={(_, resultItems) =>
                                  validation.setFieldValue("users", resultItems)
                                }
                              /> */}
                              <FormFeedback type="invalid">
                                {validation.touched.users &&
                                  validation.errors.users}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AccountsList);

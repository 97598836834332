import { call, put, takeEvery } from "redux-saga/effects";

// Admin Redux States
import {
  GET_ADMINS,
  GET_ADMIN_DETAIL,
  ADD_NEW_ADMIN,
  DELETE_ADMIN,
  UPDATE_ADMIN,
} from "./actionTypes";
import {
  getAdminsSuccess,
  getAdminsFail,
  getAdminDetailSuccess,
  getAdminDetailFail,
  addAdminFail,
  addAdminSuccess,
  updateAdminSuccess,
  updateAdminFail,
  deleteAdminSuccess,
  deleteAdminFail,
} from "./actions";
import { AdminApi } from "helpers/Api";

function* fetchAdmins() {
  try {
    const response = yield call(AdminApi.all);
    if (response.success) {
      yield put(getAdminsSuccess(response.data));
    } else {
      yield put(getAdminsFail(response.message));
    }
  } catch (error) {
    yield put(getAdminsFail(error));
  }
}

function* fetchAdminDetail({ adminId }) {
  try {
    if (adminId === undefined) {
      yield put(getAdminDetailSuccess(null));
    } else {
      const response = yield call(AdminApi.show, adminId);
      if (response.success) {
        yield put(getAdminDetailSuccess(response.data));
      } else {
        yield put(getAdminDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getAdminDetailFail(error));
  }
}

function* onUpdateAdmin({ payload: admin }) {
  try {
    const response = yield call(AdminApi.update, admin);
    if (response.success) {
      yield put(updateAdminSuccess(response.data));
    } else {
      yield put(updateAdminFail(response.message));
    }
  } catch (error) {
    yield put(updateAdminFail(error));
  }
}

function* onDeleteAdmin({ payload: admin }) {
  try {
    const response = yield call(AdminApi.destroy, admin);
    if (response.success) {
      yield put(deleteAdminSuccess(response.data));
    } else {
      yield put(deleteAdminFail(response.message));
    }
  } catch (error) {
    yield put(deleteAdminFail(error));
  }
}

function* onAddNewAdmin({ payload: admin }) {
  try {
    const response = yield call(AdminApi.create, admin);
    if (response.success) {
      yield put(addAdminSuccess(response.data));
    } else {
      yield put(addAdminFail(response.message));
    }
  } catch (error) {
    yield put(addAdminFail(error));
  }
}

function* adminsSaga() {
  yield takeEvery(GET_ADMINS, fetchAdmins);
  yield takeEvery(GET_ADMIN_DETAIL, fetchAdminDetail);
  yield takeEvery(ADD_NEW_ADMIN, onAddNewAdmin);
  yield takeEvery(UPDATE_ADMIN, onUpdateAdmin);
  yield takeEvery(DELETE_ADMIN, onDeleteAdmin);
}

export default adminsSaga;

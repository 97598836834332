import {
  GET_PROPERTY_DETAIL,
  GET_PROPERTY_DETAIL_FAIL,
  GET_PROPERTY_DETAIL_SUCCESS,
  GET_PROPERTIES,
  GET_PROPERTIES_FAIL,
  GET_PROPERTIES_SUCCESS,
  ADD_NEW_PROPERTY,
  ADD_PROPERTY_SUCCESS,
  ADD_PROPERTY_FAIL,
  UPDATE_PROPERTY,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAIL,
  DELETE_PROPERTY,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAIL,
} from "./actionTypes";

export const getProperties = () => ({
  type: GET_PROPERTIES,
});

export const getPropertiesSuccess = users => ({
  type: GET_PROPERTIES_SUCCESS,
  payload: users,
});

export const addNewProperty = property => ({
  type: ADD_NEW_PROPERTY,
  payload: property,
});

export const addPropertySuccess = property => ({
  type: ADD_PROPERTY_SUCCESS,
  payload: property,
});

export const addPropertyFail = error => ({
  type: ADD_PROPERTY_FAIL,
  payload: error,
});

export const getPropertiesFail = error => ({
  type: GET_PROPERTIES_FAIL,
  payload: error,
});

export const getPropertyDetail = () => ({
  type: GET_PROPERTY_DETAIL,
});

export const getPropertyDetailSuccess = userProfile => ({
  type: GET_PROPERTY_DETAIL_SUCCESS,
  payload: userProfile,
});

export const getPropertyDetailFail = error => ({
  type: GET_PROPERTY_DETAIL_FAIL,
  payload: error,
});

export const updateProperty = property => ({
  type: UPDATE_PROPERTY,
  payload: property,
});

export const updatePropertySuccess = property => ({
  type: UPDATE_PROPERTY_SUCCESS,
  payload: property,
});

export const updatePropertyFail = error => ({
  type: UPDATE_PROPERTY_FAIL,
  payload: error,
});

export const deleteProperty = property => ({
  type: DELETE_PROPERTY,
  payload: property,
});

export const deletePropertySuccess = property => ({
  type: DELETE_PROPERTY_SUCCESS,
  payload: property,
});

export const deletePropertyFail = error => ({
  type: DELETE_PROPERTY_FAIL,
  payload: error,
});

/* ADMINS */
export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMINS_FAIL = "GET_ADMINS_FAIL";

/* ADMINS DETAIL*/
export const GET_ADMIN_DETAIL = "GET_ADMIN_DETAIL";
export const GET_ADMIN_DETAIL_SUCCESS = "GET_ADMIN_DETAIL_SUCCESS";
export const GET_ADMIN_DETAIL_FAIL = "GET_ADMIN_DETAIL_FAIL";

export const ADD_NEW_ADMIN = "ADD_NEW_ADMIN";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAIL = "ADD_ADMIN_FAIL";

export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAIL = "UPDATE_ADMIN_FAIL";

export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL";

import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  FormGroup,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getProperties as onGetProperties,
  addNewProperty as onAddNewProperty,
  updateProperty as onUpdateProperty,
  deleteProperty as onDeleteProperty,
  getProjects,
  getAccounts,
  getPropertyTerms,
} from "store/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import classnames from "classnames";
import Switch from "react-switch";

const PropertiesList = props => {
  //meta title
  document.title = "Properties List | RKI";

  const dispatch = useDispatch();
  const [property, setProperties] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);

  const toggleTab = async tab => {
    if (activeTab !== tab) {
      const v = await validation.validateForm();
      if (Object.keys(v).length > 0) {
        validation.setTouched(v);
        return false;
      }
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 6) {
        setActiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };

  // validation
  let validationSchema = {
    location: Yup.string().required(),
    developer_id: Yup.number().required(),
    project_id: Yup.number().required(),
    delivery_date: Yup.date().required(),
    project_phase: Yup.string().required(),
  };
  if (activeTab === 1) {
    validationSchema = {
      location: Yup.string().required(),
      developer_id: Yup.number().required(),
      project_id: Yup.number().required(),
      delivery_date: Yup.date().required(),
      project_phase: Yup.string().required(),
    };
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      location: property?.location ?? "",
      developer_id: property?.developer_id ?? "",
      unit_type: property?.unit_type ?? "",
      finishing_specs: property?.finishing_specs ?? "",
      unit_status: property?.unit_status ?? "",
      project_id: property?.project_id ?? "",
      nanny_maid_room: property?.nanny_maid_room ?? false,
      driver_room: property?.driver_room ?? false,
      delivery_date: property?.delivery_date ?? "",
      project_phase: property?.project_phase ?? "",
      unit_total_price: property?.unit_total_price ?? "",
      built_up_area: property?.built_up_area ?? "",
      no_of_rooms: property?.no_of_rooms ?? "",
      floor_no: property?.floor_no ?? "",
      unit_type_details: property?.unit_type_details ?? "",
      sqm_price: property?.sqm_price ?? "",
      payment_terms: property?.payment_terms ?? "",
      installment_period: property?.installment_period ?? "",
      unit_discount_percentage: property?.unit_discount_percentage ?? "",
      down_payment_percentage: property?.down_payment_percentage ?? "",
      down_payment_value: property?.down_payment_value ?? "",
      other_payment_percentage: property?.other_payment_percentage ?? "",
      other_payment_value: property?.other_payment_value ?? "",
      cash_back_terms: property?.cash_back_terms ?? "",
      cash_discount_terms: property?.cash_discount_terms ?? "",
      cash_discount_percentage: property?.cash_discount_percentage ?? "",
      maintenance_terms: property?.maintenance_terms ?? "",
      maintenance_percentage: property?.maintenance_percentage ?? "",
      maintenance_value: property?.maintenance_value ?? "",
      gross_net_ratio: property?.gross_net_ratio ?? "",
      garden_area: property?.garden_area ?? "",
      land_area: property?.land_area ?? "",
      garage_area: property?.garage_area ?? "",
      penthouse_area: property?.penthouse_area ?? "",
      roof_area: property?.roof_area ?? "",
      basement_area: property?.basement_area ?? "",
      pergola_area: property?.pergola_area ?? "",
      outdoor_area: property?.outdoor_area ?? "",
      terrace_area: property?.terrace_area ?? "",
      storage_area: property?.storage_area ?? "",
      no_parking_slots: property?.no_parking_slots ?? "",
      unit_design: property?.unit_design ?? "",
      unit_code: property?.unit_code ?? "",
      property_ownership: property?.property_ownership ?? "",
      wind_direction: property?.wind_direction ?? "",
      unit_view: property?.unit_view ?? "",
      rental_terms: property?.rental_terms ?? "",
      incentive: property?.incentive ?? "",
      offer_description: property?.offer_description ?? "",
      contact: property?.contact ?? "",
      comment: property?.comment ?? "",
    },
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      if (isEdit) {
        const updateProperty = {
          ...values,
          id: property.id,
        };

        // update property
        dispatch(onUpdateProperty(updateProperty));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newProperty = {
          ...values,
        };
        // save new property
        dispatch(onAddNewProperty(newProperty));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { properties, projects, developers, propertyTerms } = useSelector(
    state => ({
      properties: state.Properties.properties,
      projects: state.projects.projects,
      developers: state.Accounts.accounts.filter(
        account => account.type === "developer"
      ),
      propertyTerms: state.PropertyTerms.propertyTerms,
    })
  );

  const [userList, setPropertyList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
      {
        Header: "Location",
        accessor: "location",
        filterable: true,
      },
      {
        Header: "Unit Type",
        accessor: "unit_type",
        filterable: true,
      },
      {
        Header: "Price",
        accessor: "unit_total_price",
        filterable: true,
      },
      {
        Header: "Built Up Area",
        accessor: "built_up_area",
        filterable: true,
      },
      {
        Header: "No Of Rooms",
        accessor: "no_of_rooms",
        filterable: true,
      },
      {
        Header: "Project name",
        accessor: "project_id",
        filterable: true,
        Cell: cellProps =>
          projects.find(p => parseInt(p.id) === parseInt(cellProps.value))
            ?.name ?? "",
      },
      {
        Header: "Developer name",
        accessor: "developer_id",
        filterable: true,
        Cell: cellProps =>
          developers.find(p => parseInt(p.id) === parseInt(cellProps.value))
            ?.name ?? "",
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handlePropertyClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (projects && !projects.length) {
      dispatch(getProjects());
    }
    if (developers && !developers.length) {
      dispatch(getAccounts());
    }
    if (properties && !properties.length) {
      dispatch(onGetProperties());
      setIsEdit(false);
    }
    if (propertyTerms && !propertyTerms.length) {
      dispatch(getPropertyTerms());
    }
  }, [dispatch]);

  useEffect(() => {
    setProperties(properties);
    setIsEdit(false);
  }, [properties]);

  useEffect(() => {
    if (!isEmpty(properties) && !!isEdit) {
      setProperties(properties);
      setIsEdit(false);
    }
  }, [properties]);

  const toggle = () => {
    setModal(!modal);
    setActiveTab(1);
    setPassedSteps([1]);
  };

  const handlePropertyClick = arg => {
    setProperties(arg);
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = properties => {
    setProperties(properties);
    setDeleteModal(true);
  };

  const handleDeleteProperty = () => {
    dispatch(onDeleteProperty(property.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handlePropertyClicks = () => {
    setPropertyList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProperty}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Properties" breadcrumbItem="Properties List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={properties}
                    isGlobalFilter={true}
                    isAddOptions={"Properties"}
                    handleOptionClicks={handlePropertyClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal size="lg" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Property" : "Add Property"}
                    </ModalHeader>
                    <ModalBody>
                      <div className="wizard clearfix">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({
                                current: activeTab === 1,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                                onClick={() => {
                                  toggleTab(1);
                                }}
                                disabled={!(passedSteps || []).includes(1)}
                              >
                                <span className="number">1</span>
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 2,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 2,
                                })}
                                onClick={() => {
                                  toggleTab(2);
                                }}
                                disabled={!(passedSteps || []).includes(2)}
                              >
                                <span className="number">2</span>
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 3,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 3,
                                })}
                                onClick={() => {
                                  toggleTab(3);
                                }}
                                disabled={!(passedSteps || []).includes(3)}
                              >
                                <span className="number">3</span>
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 4,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 4,
                                })}
                                onClick={() => {
                                  setActiveTab(4);
                                }}
                                disabled={!(passedSteps || []).includes(4)}
                              >
                                <span className="number">4</span>
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 5,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 5,
                                })}
                                onClick={() => {
                                  setActiveTab(5);
                                }}
                                disabled={!(passedSteps || []).includes(5)}
                              >
                                <span className="number">5</span>
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 6,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 6,
                                })}
                                onClick={() => {
                                  setActiveTab(6);
                                }}
                                disabled={!(passedSteps || []).includes(6)}
                              >
                                <i className="mdi mdi-check-circle-outline text-success display-icon" />
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix">
                          <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                              <Form>
                                <Row>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>Location</Label>
                                      <Select
                                        value={propertyTerms
                                          .filter(
                                            term => term.key === "locations"
                                          )
                                          .find(
                                            term =>
                                              term.value ===
                                              validation.values.location
                                          )}
                                        options={propertyTerms.filter(
                                          term => term.key === "locations"
                                        )}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.value}
                                        onChange={option =>
                                          validation.setFieldValue(
                                            "location",
                                            option?.value
                                          )
                                        }
                                        className={
                                          validation.touched.location &&
                                          validation.errors.location
                                            ? "is-invalid"
                                            : ""
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.location &&
                                          validation.errors.location}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>Developer</Label>
                                      <Select
                                        isClearable
                                        value={developers.find(
                                          d =>
                                            parseInt(d.id) ===
                                            parseInt(
                                              validation.values.developer_id
                                            )
                                        )}
                                        options={developers}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        onChange={option => {
                                          validation.setFieldValue(
                                            "project_id",
                                            ""
                                          );
                                          validation.setFieldValue(
                                            "developer_id",
                                            option?.id ?? ""
                                          );
                                        }}
                                        className={
                                          validation.touched.developer_id &&
                                          validation.errors.developer_id
                                            ? "is-invalid"
                                            : ""
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.developer_id &&
                                          validation.errors.developer_id}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup className="mb-3">
                                      <Label>Project</Label>
                                      <Select
                                        value={
                                          projects.find(
                                            d =>
                                              parseInt(d.id) ===
                                              parseInt(
                                                validation.values.project_id
                                              )
                                          ) ?? ""
                                        }
                                        options={projects.filter(
                                          p =>
                                            parseInt(p.developer_id) ===
                                            parseInt(
                                              validation.values.developer_id
                                            )
                                        )}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        onChange={option =>
                                          validation.setFieldValue(
                                            "project_id",
                                            option?.id
                                          )
                                        }
                                        className={
                                          validation.touched.project_id &&
                                          validation.errors.project_id
                                            ? "is-invalid"
                                            : ""
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.project_id &&
                                          validation.errors.project_id}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup className="mb-3">
                                      <Label>Delivery Date</Label>
                                      <Input
                                        type="date"
                                        className="form-control"
                                        name="delivery_date"
                                        value={validation.values.delivery_date}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.delivery_date &&
                                          validation.errors.delivery_date
                                            ? true
                                            : false
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.delivery_date &&
                                          validation.errors.delivery_date}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup className="mb-3">
                                      <Label>Project Phase</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="project_phase"
                                        value={validation.values.project_phase}
                                        placeholder="Enter Project Phase"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.project_phase &&
                                          validation.errors.project_phase
                                            ? true
                                            : false
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.project_phase &&
                                          validation.errors.project_phase}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Form>
                            </TabPane>
                            <TabPane tabId={2}>
                              <Form>
                                <Row>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>Unit Type</Label>
                                      <Select
                                        value={propertyTerms
                                          .filter(
                                            term => term.key === "unit-types"
                                          )
                                          .find(
                                            term =>
                                              term.value ===
                                              validation.values.unit_type
                                          )}
                                        options={propertyTerms.filter(
                                          term => term.key === "unit-types"
                                        )}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.value}
                                        onChange={option =>
                                          validation.setFieldValue(
                                            "unit_type",
                                            option?.value
                                          )
                                        }
                                        className={
                                          validation.touched.unit_type &&
                                          validation.errors.unit_type
                                            ? "is-invalid"
                                            : ""
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.unit_type &&
                                          validation.errors.unit_type}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>Unit Total Price</Label>
                                      <Input
                                        type="number"
                                        step="any"
                                        className="form-control"
                                        name="unit_total_price"
                                        value={
                                          validation.values.unit_total_price
                                        }
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.unit_total_price &&
                                          validation.errors.unit_total_price
                                            ? true
                                            : false
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.unit_total_price &&
                                          validation.errors.unit_total_price}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>Built Up Area</Label>
                                      <Input
                                        type="number"
                                        step="any"
                                        className="form-control"
                                        name="built_up_area"
                                        value={validation.values.built_up_area}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.built_up_area &&
                                          validation.errors.built_up_area
                                            ? true
                                            : false
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.built_up_area &&
                                          validation.errors.built_up_area}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>No of Rooms</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="no_of_rooms"
                                        value={validation.values.no_of_rooms}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.no_of_rooms &&
                                          validation.errors.no_of_rooms
                                            ? true
                                            : false
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.no_of_rooms &&
                                          validation.errors.no_of_rooms}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>Floor No</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="floor_no"
                                        value={validation.values.floor_no}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.floor_no &&
                                          validation.errors.floor_no
                                            ? true
                                            : false
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.floor_no &&
                                          validation.errors.floor_no}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>Finishing Specs</Label>
                                      <Select
                                        value={propertyTerms
                                          .filter(
                                            term =>
                                              term.key === "finishing-specs"
                                          )
                                          .find(
                                            term =>
                                              term.value ===
                                              validation.values.finishing_specs
                                          )}
                                        options={propertyTerms.filter(
                                          term => term.key === "finishing-specs"
                                        )}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.value}
                                        onChange={option =>
                                          validation.setFieldValue(
                                            "finishing_specs",
                                            option?.value
                                          )
                                        }
                                        className={
                                          validation.touched.finishing_specs &&
                                          validation.errors.finishing_specs
                                            ? "is-invalid"
                                            : ""
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.finishing_specs &&
                                          validation.errors.finishing_specs}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>Unit Type Details</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="unit_type_details"
                                        value={
                                          validation.values.unit_type_details
                                        }
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched
                                            .unit_type_details &&
                                          validation.errors.unit_type_details
                                            ? true
                                            : false
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.unit_type_details &&
                                          validation.errors.unit_type_details}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>sqm Price</Label>
                                      <Input
                                        type="number"
                                        step="any"
                                        className="form-control"
                                        name="sqm_price"
                                        value={validation.values.sqm_price}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.sqm_price &&
                                          validation.errors.sqm_price
                                            ? true
                                            : false
                                        }
                                      />
                                      <FormFeedback type="invalid">
                                        {validation.touched.sqm_price &&
                                          validation.errors.sqm_price}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Form>
                            </TabPane>
                            <TabPane tabId={3}>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Payment Terms</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="payment_terms"
                                      value={validation.values.payment_terms}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.payment_terms &&
                                        validation.errors.payment_terms
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.payment_terms &&
                                        validation.errors.payment_terms}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Installment Period (Months)</Label>
                                    <Input
                                      type="number"
                                      step="0.1"
                                      className="form-control"
                                      name="installment_period"
                                      value={
                                        validation.values.installment_period
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.installment_period &&
                                        validation.errors.installment_period
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.installment_period &&
                                        validation.errors.installment_period}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Unit Discount Percentage</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="unit_discount_percentage"
                                      value={
                                        validation.values
                                          .unit_discount_percentage
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched
                                          .unit_discount_percentage &&
                                        validation.errors
                                          .unit_discount_percentage
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched
                                        .unit_discount_percentage &&
                                        validation.errors
                                          .unit_discount_percentage}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Down Payment Percentage</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="down_payment_percentage"
                                      value={
                                        validation.values
                                          .down_payment_percentage
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched
                                          .down_payment_percentage &&
                                        validation.errors
                                          .down_payment_percentage
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched
                                        .down_payment_percentage &&
                                        validation.errors
                                          .down_payment_percentage}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Down Payment Value</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="down_payment_value"
                                      value={
                                        validation.values.down_payment_value
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.down_payment_value &&
                                        validation.errors.down_payment_value
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.down_payment_value &&
                                        validation.errors.down_payment_value}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Other Payment Percentage</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="other_payment_percentage"
                                      value={
                                        validation.values
                                          .other_payment_percentage
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched
                                          .other_payment_percentage &&
                                        validation.errors
                                          .other_payment_percentage
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched
                                        .other_payment_percentage &&
                                        validation.errors
                                          .other_payment_percentage}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Other Payment Value</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="other_payment_value"
                                      value={
                                        validation.values.other_payment_value
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched
                                          .other_payment_value &&
                                        validation.errors.other_payment_value
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.other_payment_value &&
                                        validation.errors.other_payment_value}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Cash Back Terms</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="cash_back_terms"
                                      value={validation.values.cash_back_terms}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.cash_back_terms &&
                                        validation.errors.cash_back_terms
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.cash_back_terms &&
                                        validation.errors.cash_back_terms}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Cash Discount Terms</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="cash_discount_terms"
                                      value={
                                        validation.values.cash_discount_terms
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched
                                          .cash_discount_terms &&
                                        validation.errors.cash_discount_terms
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.cash_discount_terms &&
                                        validation.errors.cash_discount_terms}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Cash Discount Percentage</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="cash_discount_percentage"
                                      value={
                                        validation.values
                                          .cash_discount_percentage
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched
                                          .cash_discount_percentage &&
                                        validation.errors
                                          .cash_discount_percentage
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched
                                        .cash_discount_percentage &&
                                        validation.errors
                                          .cash_discount_percentage}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Maintenance Terms</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="maintenance_terms"
                                      value={
                                        validation.values.maintenance_terms
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.maintenance_terms &&
                                        validation.errors.maintenance_terms
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.maintenance_terms &&
                                        validation.errors.maintenance_terms}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Maintenance Percentage</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="maintenance_percentage"
                                      value={
                                        validation.values.maintenance_percentage
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched
                                          .maintenance_percentage &&
                                        validation.errors.maintenance_percentage
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched
                                        .maintenance_percentage &&
                                        validation.errors
                                          .maintenance_percentage}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Maintenance Value</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="maintenance_value"
                                      value={
                                        validation.values.maintenance_value
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.maintenance_value &&
                                        validation.errors.maintenance_value
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.maintenance_value &&
                                        validation.errors.maintenance_value}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Gross Net Ratio</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="gross_net_ratio"
                                      value={validation.values.gross_net_ratio}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.gross_net_ratio &&
                                        validation.errors.gross_net_ratio
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.gross_net_ratio &&
                                        validation.errors.gross_net_ratio}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={4}>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Garden Area</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="garden_area"
                                      value={validation.values.garden_area}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.garden_area &&
                                        validation.errors.garden_area
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.garden_area &&
                                        validation.errors.garden_area}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Land Area</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="land_area"
                                      value={validation.values.land_area}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.land_area &&
                                        validation.errors.land_area
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.land_area &&
                                        validation.errors.land_area}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Garage Area</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="garage_area"
                                      value={validation.values.garage_area}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.garage_area &&
                                        validation.errors.garage_area
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.garage_area &&
                                        validation.errors.garage_area}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Penthouse Area</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="penthouse_area"
                                      value={validation.values.penthouse_area}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.penthouse_area &&
                                        validation.errors.penthouse_area
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.penthouse_area &&
                                        validation.errors.penthouse_area}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Roof Area</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="roof_area"
                                      value={validation.values.roof_area}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.roof_area &&
                                        validation.errors.roof_area
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.roof_area &&
                                        validation.errors.roof_area}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Basement Area</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="basement_area"
                                      value={validation.values.basement_area}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.basement_area &&
                                        validation.errors.basement_area
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.basement_area &&
                                        validation.errors.basement_area}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Pergola Area</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="pergola_area"
                                      value={validation.values.pergola_area}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.pergola_area &&
                                        validation.errors.pergola_area
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.pergola_area &&
                                        validation.errors.pergola_area}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Outdoor Area</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="outdoor_area"
                                      value={validation.values.outdoor_area}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.outdoor_area &&
                                        validation.errors.outdoor_area
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.outdoor_area &&
                                        validation.errors.outdoor_area}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Terrace Area</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="terrace_area"
                                      value={validation.values.terrace_area}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.terrace_area &&
                                        validation.errors.terrace_area
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.terrace_area &&
                                        validation.errors.terrace_area}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Storage Area</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="storage_area"
                                      value={validation.values.storage_area}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.storage_area &&
                                        validation.errors.storage_area
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.storage_area &&
                                        validation.errors.storage_area}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>No Parking Slots</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="no_parking_slots"
                                      value={validation.values.no_parking_slots}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.no_parking_slots &&
                                        validation.errors.no_parking_slots
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.no_parking_slots &&
                                        validation.errors.no_parking_slots}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Nanny Maid Room</Label>
                                    <Switch
                                      checked={
                                        validation.values.nanny_maid_room
                                      }
                                      onChange={option =>
                                        validation.setFieldValue(
                                          "nanny_maid_room",
                                          option
                                        )
                                      }
                                      className={`d-block ${
                                        validation.touched.nanny_maid_room &&
                                        validation.errors.nanny_maid_room
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.nanny_maid_room &&
                                        validation.errors.nanny_maid_room}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Driver Room</Label>
                                    <Switch
                                      checked={validation.values.driver_room}
                                      onChange={option =>
                                        validation.setFieldValue(
                                          "driver_room",
                                          option
                                        )
                                      }
                                      className={`d-block ${
                                        validation.touched.driver_room &&
                                        validation.errors.driver_room
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.driver_room &&
                                        validation.errors.driver_room}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6"></Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={5}>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Unit Design</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="unit_design"
                                      value={validation.values.unit_design}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.unit_design &&
                                        validation.errors.unit_design
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.unit_design &&
                                        validation.errors.unit_design}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Unit Status</Label>
                                    <Select
                                      value={propertyTerms
                                        .filter(
                                          term => term.key === "unit-status"
                                        )
                                        .find(
                                          term =>
                                            term.value ===
                                            validation.values.unit_status
                                        )}
                                      options={propertyTerms.filter(
                                        term => term.key === "unit-status"
                                      )}
                                      getOptionValue={option => option.id}
                                      getOptionLabel={option => option.value}
                                      onChange={option =>
                                        validation.setFieldValue(
                                          "unit_status",
                                          option?.value
                                        )
                                      }
                                      className={
                                        validation.touched.unit_status &&
                                        validation.errors.unit_status
                                          ? "is-invalid"
                                          : ""
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.unit_status &&
                                        validation.errors.unit_status}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Unit Code</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="unit_code"
                                      value={validation.values.unit_code}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.unit_code &&
                                        validation.errors.unit_code
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.unit_code &&
                                        validation.errors.unit_code}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Property Ownership</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="property_ownership"
                                      value={
                                        validation.values.property_ownership
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.property_ownership &&
                                        validation.errors.property_ownership
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.property_ownership &&
                                        validation.errors.property_ownership}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Wind Direction</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="wind_direction"
                                      value={validation.values.wind_direction}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.wind_direction &&
                                        validation.errors.wind_direction
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.wind_direction &&
                                        validation.errors.wind_direction}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Unit View</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="unit_view"
                                      value={validation.values.unit_view}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.unit_view &&
                                        validation.errors.unit_view
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.unit_view &&
                                        validation.errors.unit_view}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Rental Terms</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="rental_terms"
                                      value={validation.values.rental_terms}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.rental_terms &&
                                        validation.errors.rental_terms
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.rental_terms &&
                                        validation.errors.rental_terms}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Incentive</Label>
                                    <Input
                                      type="number"
                                      step="any"
                                      className="form-control"
                                      name="incentive"
                                      value={validation.values.incentive}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.incentive &&
                                        validation.errors.incentive
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.incentive &&
                                        validation.errors.incentive}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Offer Description</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="offer_description"
                                      value={
                                        validation.values.offer_description
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.offer_description &&
                                        validation.errors.offer_description
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.offer_description &&
                                        validation.errors.offer_description}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Contact</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="contact"
                                      value={validation.values.contact}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.contact &&
                                        validation.errors.contact
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.contact &&
                                        validation.errors.contact}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label>Comment</Label>
                                    <Input
                                      type="textarea"
                                      rows="3"
                                      className="form-control"
                                      name="comment"
                                      value={validation.values.comment}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.comment &&
                                        validation.errors.comment
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormFeedback type="invalid">
                                      {validation.touched.comment &&
                                        validation.errors.comment}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={6}>
                              <div className="row justify-content-center">
                                <h5 className="text-center mb-4">
                                  Confirm Details
                                </h5>

                                <Row>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Location</b>
                                    <p>{validation.values.location}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Developer</b>
                                    <p>
                                      {
                                        developers.find(
                                          d =>
                                            parseInt(d.id) ===
                                            parseInt(
                                              validation.values.developer_id
                                            )
                                        )?.name
                                      }
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Project</b>
                                    <p>
                                      {
                                        projects.find(
                                          d =>
                                            parseInt(d.id) ===
                                            parseInt(
                                              validation.values.project_id
                                            )
                                        )?.name
                                      }
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Delivery Date</b>
                                    <p>{validation.values.delivery_date}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Project Phase</b>
                                    <p>{validation.values.project_phase}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Unit Type</b>
                                    <p>{validation.values.unit_type}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Unit Total Price</b>
                                    <p>{validation.values.unit_total_price}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Built Up Area</b>
                                    <p>{validation.values.built_up_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>No of Rooms</b>
                                    <p>{validation.values.no_of_rooms}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Floor No</b>
                                    <p>{validation.values.floor_no}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Finishing Specs</b>
                                    <p>{validation.values.finishing_specs}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Unit Type Details</b>
                                    <p>{validation.values.unit_type_details}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>sqm Price</b>
                                    <p>{validation.values.sqm_price}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Payment Terms</b>
                                    <p>{validation.values.payment_terms}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Installment Period (Months)</b>
                                    <p>
                                      {validation.values.installment_period}
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Unit Discount Percentage</b>
                                    <p>
                                      {
                                        validation.values
                                          .unit_discount_percentage
                                      }
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Down Payment Percentage</b>
                                    <p>
                                      {
                                        validation.values
                                          .down_payment_percentage
                                      }
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Down Payment Value</b>
                                    <p>
                                      {validation.values.down_payment_value}
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Other Payment Percentage</b>
                                    <p>
                                      {
                                        validation.values
                                          .other_payment_percentage
                                      }
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Other Payment Value</b>
                                    <p>
                                      {validation.values.other_payment_value}
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Cash Back Terms</b>
                                    <p>{validation.values.cash_back_terms}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Cash Discount Terms</b>
                                    <p>
                                      {validation.values.cash_discount_terms}
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Cash Discount Percentage</b>
                                    <p>
                                      {
                                        validation.values
                                          .cash_discount_percentage
                                      }
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Maintenance Terms</b>
                                    <p>{validation.values.maintenance_terms}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Maintenance Percentage</b>
                                    <p>
                                      {validation.values.maintenance_percentage}
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Maintenance Value</b>
                                    <p>{validation.values.maintenance_value}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Gross Net Ratio</b>
                                    <p>{validation.values.gross_net_ratio}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Garden Area</b>
                                    <p>{validation.values.garden_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Land Area</b>
                                    <p>{validation.values.land_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Garage Area</b>
                                    <p>{validation.values.garage_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Penthouse Area</b>
                                    <p>{validation.values.penthouse_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Roof Area</b>
                                    <p>{validation.values.roof_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Basement Area</b>
                                    <p>{validation.values.basement_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Pergola Area</b>
                                    <p>{validation.values.pergola_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Outdoor Area</b>
                                    <p>{validation.values.outdoor_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Terrace Area</b>
                                    <p>{validation.values.terrace_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Storage Area</b>
                                    <p>{validation.values.storage_area}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>No Parking Slots</b>
                                    <p>{validation.values.no_parking_slots}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Nanny Maid Room</b>
                                    <p>
                                      {validation.values.nanny_maid_room
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Driver Room</b>
                                    <p>
                                      {validation.values.driver_room
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Unit Design</b>
                                    <p>{validation.values.unit_design}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Unit Status</b>
                                    <p>{validation.values.unit_status}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Unit Code</b>
                                    <p>{validation.values.unit_code}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Property Ownership</b>
                                    <p>
                                      {validation.values.property_ownership}
                                    </p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Wind Direction</b>
                                    <p>{validation.values.wind_direction}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Unit View</b>
                                    <p>{validation.values.unit_view}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Rental Terms</b>
                                    <p>{validation.values.rental_terms}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Incentive</b>
                                    <p>{validation.values.incentive}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Offer Description</b>
                                    <p>{validation.values.offer_description}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Contact</b>
                                    <p>{validation.values.contact}</p>
                                  </Col>
                                  <Col lg="4" className="border p-2 pb-0">
                                    <b>Comment</b>
                                    <p>{validation.values.comment}</p>
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                activeTab === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1);
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={
                                activeTab === 6 ? "next disabled" : "next"
                              }
                            >
                              {(activeTab < 6 && (
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab + 1);
                                  }}
                                >
                                  Next
                                </Link>
                              )) || (
                                <Button
                                  color="success"
                                  onClick={validation.handleSubmit}
                                >
                                  Save
                                </Button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PropertiesList);

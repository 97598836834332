import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNT_DETAIL,
  GET_ACCOUNT_DETAIL_FAIL,
  GET_ACCOUNT_DETAIL_SUCCESS,
  ADD_NEW_ACCOUNT,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_FAIL,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL,
} from "./actionTypes";

export const getAccounts = () => ({
  type: GET_ACCOUNTS,
});

export const getAccountsSuccess = accounts => ({
  type: GET_ACCOUNTS_SUCCESS,
  payload: accounts,
});

export const getAccountsFail = error => ({
  type: GET_ACCOUNTS_FAIL,
  payload: error,
});

export const getAccountDetail = accountId => ({
  type: GET_ACCOUNT_DETAIL,
  accountId,
});

export const getAccountDetailSuccess = accountDetails => ({
  type: GET_ACCOUNT_DETAIL_SUCCESS,
  payload: accountDetails,
});

export const getAccountDetailFail = error => ({
  type: GET_ACCOUNT_DETAIL_FAIL,
  payload: error,
});

export const addNewAccount = account => ({
  type: ADD_NEW_ACCOUNT,
  payload: account,
});

export const addAccountSuccess = account => ({
  type: ADD_ACCOUNT_SUCCESS,
  payload: account,
});

export const addAccountFail = error => ({
  type: ADD_ACCOUNT_FAIL,
  payload: error,
});

export const updateAccount = account => ({
  type: UPDATE_ACCOUNT,
  payload: account,
});

export const updateAccountSuccess = account => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  payload: account,
});

export const updateAccountFail = error => ({
  type: UPDATE_ACCOUNT_FAIL,
  payload: error,
});

export const deleteAccount = account => ({
  type: DELETE_ACCOUNT,
  payload: account,
});

export const deleteAccountSuccess = account => ({
  type: DELETE_ACCOUNT_SUCCESS,
  payload: account,
});

export const deleteAccountFail = error => ({
  type: DELETE_ACCOUNT_FAIL,
  payload: error,
});

import { call, put, takeEvery } from "redux-saga/effects";

// Property Redux States
import {
  GET_PROPERTIES,
  GET_PROPERTY_DETAIL,
  ADD_NEW_PROPERTY,
  DELETE_PROPERTY,
  UPDATE_PROPERTY,
} from "./actionTypes";
import {
  getPropertiesSuccess,
  getPropertiesFail,
  getPropertyDetailSuccess,
  getPropertyDetailFail,
  addPropertyFail,
  addPropertySuccess,
  updatePropertySuccess,
  updatePropertyFail,
  deletePropertySuccess,
  deletePropertyFail,
} from "./actions";

//Include Both Helper File with needed methods
import { PropertyApi } from "helpers/Api";

function* fetchProperties() {
  try {
    const response = yield call(PropertyApi.all);
    if (response.success) {
      yield put(getPropertiesSuccess(response.data));
    } else {
      yield put(getPropertiesFail(response.message));
    }
  } catch (error) {
    yield put(getPropertiesFail(error));
  }
}

function* fetchPropertyDetail({ propertyId }) {
  try {
    if (propertyId === undefined) {
      yield put(getPropertyDetailSuccess(null));
    } else {
      const response = yield call(PropertyApi.show, propertyId);
      if (response.success) {
        yield put(getPropertyDetailSuccess(response.data));
      } else {
        yield put(getPropertyDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getPropertyDetailFail(error));
  }
}

function* onUpdateProperty({ payload: property }) {
  try {
    const response = yield call(PropertyApi.update, property);
    if (response.success) {
      yield put(updatePropertySuccess(response.data));
    } else {
      yield put(updatePropertyFail(response.message));
    }
  } catch (error) {
    yield put(updatePropertyFail(error));
  }
}

function* onDeleteProperty({ payload: property }) {
  try {
    const response = yield call(PropertyApi.destroy, property);
    if (response.success) {
      yield put(deletePropertySuccess(response.data));
    } else {
      yield put(deletePropertyFail(response.message));
    }
  } catch (error) {
    yield put(deletePropertyFail(error));
  }
}

function* onAddNewProperty({ payload: property }) {
  try {
    const response = yield call(PropertyApi.create, property);
    if (response.success) {
      yield put(addPropertySuccess(response.data));
    } else {
      yield put(addPropertyFail(response.message));
    }
  } catch (error) {
    yield put(addPropertyFail(error));
  }
}

function* propertysSaga() {
  yield takeEvery(GET_PROPERTIES, fetchProperties);
  yield takeEvery(GET_PROPERTY_DETAIL, fetchPropertyDetail);
  yield takeEvery(ADD_NEW_PROPERTY, onAddNewProperty);
  yield takeEvery(UPDATE_PROPERTY, onUpdateProperty);
  yield takeEvery(DELETE_PROPERTY, onDeleteProperty);
}

export default propertysSaga;

import {
  GET_ADMINS,
  GET_ADMINS_FAIL,
  GET_ADMINS_SUCCESS,
  GET_ADMIN_DETAIL,
  GET_ADMIN_DETAIL_FAIL,
  GET_ADMIN_DETAIL_SUCCESS,
  ADD_NEW_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAIL,
  UPDATE_ADMIN,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAIL,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAIL,
} from "./actionTypes";

export const getAdmins = () => ({
  type: GET_ADMINS,
});

export const getAdminsSuccess = admins => ({
  type: GET_ADMINS_SUCCESS,
  payload: admins,
});

export const getAdminsFail = error => ({
  type: GET_ADMINS_FAIL,
  payload: error,
});

export const getAdminDetail = adminId => ({
  type: GET_ADMIN_DETAIL,
  adminId,
});

export const getAdminDetailSuccess = adminDetails => ({
  type: GET_ADMIN_DETAIL_SUCCESS,
  payload: adminDetails,
});

export const getAdminDetailFail = error => ({
  type: GET_ADMIN_DETAIL_FAIL,
  payload: error,
});

export const addNewAdmin = admin => ({
  type: ADD_NEW_ADMIN,
  payload: admin,
});

export const addAdminSuccess = admin => ({
  type: ADD_ADMIN_SUCCESS,
  payload: admin,
});

export const addAdminFail = error => ({
  type: ADD_ADMIN_FAIL,
  payload: error,
});

export const updateAdmin = admin => ({
  type: UPDATE_ADMIN,
  payload: admin,
});

export const updateAdminSuccess = admin => ({
  type: UPDATE_ADMIN_SUCCESS,
  payload: admin,
});

export const updateAdminFail = error => ({
  type: UPDATE_ADMIN_FAIL,
  payload: error,
});

export const deleteAdmin = admin => ({
  type: DELETE_ADMIN,
  payload: admin,
});

export const deleteAdminSuccess = admin => ({
  type: DELETE_ADMIN_SUCCESS,
  payload: admin,
});

export const deleteAdminFail = error => ({
  type: DELETE_ADMIN_FAIL,
  payload: error,
});

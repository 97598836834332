import {
  GET_ADMINS_FAIL,
  GET_ADMINS_SUCCESS,
  GET_ADMIN_DETAIL_FAIL,
  GET_ADMIN_DETAIL_SUCCESS,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAIL,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAIL,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  admins: [],
  adminDetail: {},
  error: {},
};

const Admins = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.payload,
      };

    case GET_ADMINS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ADMIN_DETAIL_SUCCESS:
      return {
        ...state,
        adminDetail: action.payload,
      };

    case GET_ADMIN_DETAIL_FAIL:
      return {
        ...state,
        adminDetail: null,
        error: action.payload,
      };

    case ADD_ADMIN_SUCCESS:
      return {
        ...state,
        admins: [...state.admins, action.payload],
      };

    case ADD_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        admins: state.admins.map(admin =>
          admin.id.toString() === action.payload.id.toString()
            ? { admin, ...action.payload }
            : admin
        ),
      };

    case UPDATE_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        admins: state.admins.filter(
          admin => admin.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Admins;

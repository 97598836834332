import {
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNT_DETAIL_FAIL,
  GET_ACCOUNT_DETAIL_SUCCESS,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_FAIL,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  accounts: [],
  accountDetail: {},
  error: {},
}

const Accounts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.payload,
      }

    case GET_ACCOUNTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        accountDetail: action.payload,
      }

    case GET_ACCOUNT_DETAIL_FAIL:
      return {
        ...state,
        accountDetail: null,
        error: action.payload,
      }

    case ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: [...state.accounts, action.payload],
      }

    case ADD_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: state.accounts.map(account =>
          account.id.toString() === action.payload.id.toString()
            ? { account, ...action.payload }
            : account
        ),
      }

    case UPDATE_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: state.accounts.filter(
          account => account.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Accounts

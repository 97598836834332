import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getPropertyTerms as onGetPropertyTerms,
  addNewPropertyTerm as onAddNewPropertyTerm,
  updatePropertyTerm as onUpdatePropertyTerm,
  deletePropertyTerm as onDeletePropertyTerm,
  getPropertyTermDetail,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const PropertyTermsList = props => {
  const pathName = props.location.pathname.split("/").pop();
  const PropertyTermKey = pathName;

  //meta title
  document.title = "PropertyTerms List | RKI";

  const dispatch = useDispatch();
  const [propertyTerm, setPropertyTerm] = useState();

  const { propertyTerms, propertyTermDetail } = useSelector(state => ({
    propertyTerms: state.PropertyTerms.propertyTerms.filter(
      term => term.key === PropertyTermKey
    ),
    propertyTermDetail: state.PropertyTerms.propertyTermDetail,
  }));

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      key: PropertyTermKey,
      value: (propertyTerm && propertyTerm.value) || "",
    },
    validationSchema: Yup.object({
      value: Yup.string().required(),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatePropertyTerm = {
          ...values,
          id: propertyTermDetail.id,
        };

        // update user
        dispatch(onUpdatePropertyTerm(updatePropertyTerm));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newPropertyTerm = {
          ...values,
        };
        // save new user
        dispatch(onAddNewPropertyTerm(newPropertyTerm));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [userList, setPropertyTermList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "value",
        filterable: true,
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handlePropertyTermClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (propertyTerms && !propertyTerms.length) {
      dispatch(onGetPropertyTerms());
      setIsEdit(false);
    }
  }, [dispatch]);

  useEffect(() => {
    setPropertyTerm(propertyTermDetail);
  }, [propertyTermDetail]);

  const toggle = () => {
    setModal(!modal);
  };

  const handlePropertyTermClick = async arg => {
    await dispatch(getPropertyTermDetail(arg.id));
    setIsEdit(true);

    setModal(true);
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = propertyTerm => {
    setPropertyTerm(propertyTerm);
    setDeleteModal(true);
  };

  const handleDeletePropertyTerm = () => {
    dispatch(onDeletePropertyTerm(propertyTerm.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handlePropertyTermClicks = () => {
    dispatch(getPropertyTermDetail(0));
    setIsEdit(false);
    setModal(true);
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePropertyTerm}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={PropertyTermKey}
            breadcrumbItem={`${PropertyTermKey} List`}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={propertyTerms}
                    isGlobalFilter={true}
                    isAddOptions={PropertyTermKey}
                    handleOptionClicks={handlePropertyTermClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit " : "Add "}{" "}
                      <span className="text-capitalize">{PropertyTermKey}</span>
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Label className="form-label">Name</Label>
                              <Input
                                name="value"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.value || ""}
                                invalid={
                                  validation.touched.value &&
                                  validation.errors.value
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.touched.value &&
                                  validation.errors.value}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PropertyTermsList);
